<template>
  <div id="package-section" class="pt-10 pb-20">
    <h1 class="text-center pb-8">{{ heading() }}</h1>
    <vs-row
      vs-align="flex-start"
      vs-type="flex"
      vs-justify="left"
      class="w-full"
    >
      <div class="container">
        <vs-col
          vs-type="flex"
          vs-justify="left"
          vs-align="left"
          class="w-full md:w-1/3 mb-5 md:mb-0"
          v-for="row in packages"
          :key="row.id"
        >
          <vs-card class="package-card m-0 md:mx-5">
            <p v-if="activeSubscription(row._id)">
              <vs-button
                :color="activeSubscription(row._id) ? 'success' : 'primary'"
                class="w-full"
                @click="selectPackage(row)"
                :disabled="activeSubscription(row._id)"
              >
                {{ activeSubscription(row._id) ? "Active" : "Select" }}
              </vs-button>
            </p>
            <p v-if="activeSubscription(row._id)" class="mt-2 text-center">
              <strong style="color:red;"> {{ expireText() }}</strong>
            </p>
            <div class=" heading">
              <h3 class="m-5">{{ row.name | capitalize }}</h3>
              <h1 class="text-bold m-5">${{ row.price }}</h1>
              <p class="m-5">/ {{ row.quotaText }}</p>
            </div>
            <!-- <hr>
            <div>
              <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" class="w-full 5d61zS">
                <vs-col vs-type="flex" vs-justify="center" vs-align="left" class="w-1/2 my-2 px-5">
                  <p>{{ row.numberOfCenter }} center</p>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="left" class="last-child w-1/2 my-2 px-5">
                  <p>{{ row.numberOfChildren }} children</p>
                </vs-col>
              </vs-row>
            </div> -->
            <hr />
            <div>
              <ul class="opt mt-4 mb-4 px-5">
                <li
                  v-for="feature in features"
                  :key="feature.id"
                  class="py-2 font-medium flex items-center"
                  :style="crossIfNotIncludedStyle(feature, row)"
                >
                  <div class="round">
                    <i
                      class="material-icons"
                      :style="
                        crossIfNotIncludedStyle(feature, row)
                          ? 'color: red'
                          : ''
                      "
                    >
                      {{
                        crossIfNotIncludedStyle(feature, row)
                          ? "cancel"
                          : "check_circle"
                      }}
                    </i>
                    <span class="cursor-pointer">{{ feature.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="centerx" v-if="activeSubscription(row._id)">
              <vs-button
                color="danger"
                class="w-full"
                @click="cancelSubscription.config.popupToUploadCsv = true"
                type="filled"
                >Cancel
              </vs-button>
              <vs-popup
                title="Cancel Subscription"
                :active.sync="cancelSubscription.config.popupToUploadCsv"
                class="cancel-date-popup"
              >
                <div class="centerx">
                  <div class="datepicker-wrapper hasIcon mb-5">
                    <!--                    <vs-icon icon="calendar_today" class="icon"></vs-icon>-->
                    <!--                    <datepicker-->
                    <!--                      format="MMMM dd yyyy"-->
                    <!--                      :disabled-dates="getMonthDisable"-->
                    <!--                      v-model="cancelSubscription.data.endDate"-->
                    <!--                      placeholder="Subscription End Date"-->
                    <!--                      prepend-icon="event"-->
                    <!--                      :input-class="{-->
                    <!--                          'is-true':-->
                    <!--                            !errors.first('endDate') &&-->
                    <!--                            cancelSubscription.data.endDate,-->
                    <!--                          'is-danger': errors.first('endDate')-->
                    <!--                        }"-->
                    <!--                    />-->
                    <vue-monthly-picker
                      placeholder="Subscription End Date"
                      :dateFormat="'YYYY/MM'"
                      :min="minDate()"
                      :monthLabels="[
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sept',
                        'Oct',
                        'Nov',
                        'Dec'
                      ]"
                      v-model="cancelSubscription.data.endDate"
                    >
                    </vue-monthly-picker>
                  </div>
                  <vs-button @click="addCancelDate(row)">Submit</vs-button>
                </div>
              </vs-popup>
            </div>
            <div v-else>
              <vs-button
                :color="activeSubscription(row._id) ? 'success' : 'primary'"
                class="w-full"
                @click="selectPackage(row)"
                :disabled="activeSubscription(row._id)"
              >
                {{ activeSubscription(row._id) ? "Active" : "Select" }}
              </vs-button>
            </div>
          </vs-card>
        </vs-col>
      </div>
    </vs-row>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";

export default {
  name: "Packages",
  components: {
    Datepicker,
    VueMonthlyPicker
  },
  data() {
    return {
      packages: [],
      features: [],
      cancelSubscription: {
        config: {
          popupToUploadCsv: false,
          disabledDates: { to: new Date(2021, 1) }
        },
        data: {
          endDate: null
        }
      }
    };
  },
  methods: {
    ...mapActions("pages", ["fetchSubscriptionPackages", "fetchInclusionList"]),
    ...mapActions("userSubscription", [
      "upgradePackage",
      "addUpdateCancelDateForSubscription"
    ]),
    minDate() {
      return moment();
    },
    heading() {
      return this.activeUserInfo.subscription
        ? "Upgrade Package"
        : "Select Package";
    },
    expireText() {
      return this.activeUserInfo &&
        this.activeUserInfo.subscription.packageQueue.endDate
        ? `This package will be expired on ${moment(
            this.activeUserInfo.subscription.packageQueue.endDate
          ).format("YYYY MM")}`
        : "";
    },
    selectPackage(packageDetail) {
      const switchTo = packageDetail.type;
      const title = !this.activeUserInfo.subscription
        ? "Select Package"
        : "Package Upgrade";
      const confirmButtonText = !this.activeUserInfo.subscription
        ? "Select"
        : "Upgrade";
      const footerNote = !this.activeUserInfo.subscription
        ? ""
        : "<p><strong>[Note: This upgrade package will be effective from the 1st of the following month.]</strong></p>";

      Swal.fire({
        title: title,
        html: `
            <div>
              You are about to select ${packageDetail.slug} package. This will cost $ ${packageDetail.price} per child per month.
              ${footerNote}
            </div>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: confirmButtonText
      }).then(result => {
        if (result.isConfirmed) {
          //upgrade the package here
          //if the user is subscription less
          if (!this.activeUserInfo.subscription) {
            this.$router.push({
              name: "director-payment-details",
              params: { type: switchTo },
              query: { to: "/payment-details" }
            });
          } else {
            this.$vs.loading();
            this.upgradePackage({
              packageType: switchTo,
              packageSlug: packageDetail.slug
            })
              .then(response => {
                //update the store with new subscription packageId, no need as it will take effect at the starting of new month
                // this.$store.commit('UPDATE_USER_INFO', response.data.data)
                this.$vs.notify({
                  title: `You switched to ${packageDetail.slug} package.`,
                  text: `Your package update requested has been acknowledged successfully.`,
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "success"
                });
              })
              .catch(err => {
                this.$vs.notify({
                  title: "Failure",
                  text:
                    "Unable to update your package. Please try again later.",
                  iconPack: "feather",
                  icon: "icon-alert-circle",
                  color: "danger"
                });
              })
              .finally(() => {
                this.$vs.loading.close();
              });
          }
        }
      });
    },

    activeSubscription(subscriptionId) {
      if (!this.activeUserInfo.subscription) return false;

      return (
        subscriptionId.toString() ===
        this.activeUserInfo.subscription.subscriptionPackage._id.toString()
      );
    },

    crossIfNotIncludedStyle(feature, row) {
      // const style = "text-decoration: line-through;";
      return (
        !row.featureList
          .map(eachRow => eachRow["_id"].toString())
          .includes(feature._id.toString()) || !feature.status
      );
    },

    async addCancelDate(row) {
      await this.$validator.validateAll().then(result => {
        if (result) {
          this.customError = "";
          this.$vs.loading();
          this.addUpdateCancelDateForSubscription({
            endDate: moment(this.cancelSubscription.data.endDate).format(
              "YYYY/MM/DD"
            ),
            mySubscriptionId: this.activeUserInfo.subscription._id,
            paymentQueueId: this.activeUserInfo.subscription.packageQueue._id
          })
            .then(res => {
              this.$vs.loading.close();
              //update the store with new subscription packageId
              this.$store.commit("UPDATE_USER_INFO", res.data.data);
              this.$vs.notify({
                title: "Cancel Subscription",
                text: `End date has been added successfully.`,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success"
              });
            })
            .catch(err => {
              this.$vs.notify({
                title: "Cancel Subscription",
                text: "Could not add end date.",
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger"
              });
            })
            .finally(() => {
              this.$vs.loading.close();
              this.cancelSubscription.config.popupToUploadCsv = false;
            });
        }
      });
    }
  },
  async created() {
    try {
      let packageListResponse = await this.fetchSubscriptionPackages();
      let inclusionListResponse = await this.fetchInclusionList();
      //update the end date if exists
      if (this.activeUserInfo.subscription) {
        this.cancelSubscription.data.endDate = this.activeUserInfo.subscription.packageQueue.endDate;
      }

      packageListResponse = JSON.stringify(packageListResponse);
      if (JSON.parse(packageListResponse).status === 200)
        this.packages = JSON.parse(packageListResponse).data.data;

      inclusionListResponse = JSON.stringify(inclusionListResponse);
      if (JSON.parse(inclusionListResponse).status === 200)
        this.features = JSON.parse(inclusionListResponse).data.data;
    } catch (e) {
      // console.log(e);
    }
  },
  computed: {
    activeUserInfo() {
      console.log(
        this.$store.state.AppActiveUser,
        "this.$store.state.AppActiveUser;"
      );
      return this.$store.state.AppActiveUser;
    },
    getMonthDisable() {
      const currentYear = moment().year();
      const currentMonth = moment().month() + 1;
      return { to: new Date(currentYear, currentMonth) };
    }
  }
};
</script>

<style lang="scss">
#package-section {
  padding: 0 40px 40px;

  .package-card {
    min-height: 400px;
    margin: 20px;
  }

  .package-card-advanced {
    min-height: 440px;
    position: relative;

    .value {
      background-color: #2898d5;
      min-height: 50px;
      padding: 10px;
      position: relative;
      text-align: center;
      border-radius: 5px;

      @media (min-width: 768px) {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
      }
    }
  }
}

.heading {
  text-align: center;
}

#contact-section {
  .contact-form-section {
    @media (min-width: 768px) {
      padding: 0 0 0 60px;
    }
  }
}

.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #ffff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round {
  position: relative;
  padding-left: 35px;
}

.round .material-icons {
  color: #74cac2;
  font-size: 25px;
  position: absolute;
  left: 0;
}

.last-child {
  border-left: 1px solid #a5a5a5;
}

.contact-section-wrap {
  background-color: #fff;
}

.contact-section-wrap,
.text-section {
  h1 {
    @media (min-width: 768px) {
      font-size: 50px;
      line-height: 1;
    }
  }

  p {
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}

.vs-input--input.hasIcon:focus + .vs-input--placeholder,
.vs-con-input-label.is-label-placeholder.isFocus .vs-input--placeholder,
.vs-input--input.hasValue + .vs-placeholder-label,
.vs-input--input:focus + .vs-placeholder-label {
  padding-left: 15px !important;
}

.vs-con-textarea {
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.vs-popup--content {
  overflow: visible;
}

.cancel-date-popup .vs-popup {
  padding: 10px;
}
</style>
